<template>
  <div class="container">
    <p>
      <div class="row mb-5">
        <div class="col">
          <h1>Políticas de privacidad</h1>
          <h5>Actualización de 07 de Noviembre de 2022</h5>
        </div>
      </div>
    </p>
    <ol>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong><span data-contrast="auto">Definiciones.</span></strong
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <strong><span data-contrast="auto">Aviso: </span></strong
            ><span data-contrast="auto"
              >La integridad del texto que comprende el presente aviso de
              privacidad.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <strong
              ><span data-contrast="auto"
                >Informaci&oacute;n Personal:</span
              ></strong
            ><span data-contrast="auto">
              Informaci&oacute;n sobre el Usuario que pueda identificarlo, tal
              como su nombre, direcci&oacute;n, n&uacute;mero de tel&eacute;fono
              o direcci&oacute;n de correo electr&oacute;nico, etc.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <strong><span data-contrast="auto">Ley:</span></strong
            ><span data-contrast="auto">
              Ley Federal de Protecci&oacute;n de Datos Personales en
              Posesi&oacute;n de los Particulares</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <strong><span data-contrast="auto">LFM:</span></strong
            ><span data-contrast="auto">
              La empresa HKRE S. de R.L. de C.V., conocida comercialmente como
              &ldquo;Land Finder M&eacute;xico&rdquo;.&nbsp;</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <strong><span data-contrast="auto">Registro:</span></strong
            ><span data-contrast="auto">
              Formulario proporcionado por LFM y entrega de documentos (PDF),
              solicitados.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <strong><span data-contrast="auto">Servicios:&nbsp;</span></strong
            ><span data-contrast="auto">
              Analizar y publicar inmuebles en la Plataforma (como un Software
              as a Service), a fin de fungir como intermediario entre
              propietarios y fondos de inversi&oacute;n, inversionistas
              particulades, desarrolladores y cualquier otra persona interesada
              en realizar y/o fondear proyectos inmobiliarios en
              asociaci&oacute;n con los propietarios de los inmuebles.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <strong><span data-contrast="auto">Sitio: </span></strong
            ><a href="https://landfindermexico.com/"
              ><span data-contrast="auto"
                ><span data-ccp-charstyle="Hyperlink"
                  >https://landfindermexico.com</span
                ></span
              ></a
            ><span data-contrast="auto"
              >, dominio en el cual se encuentra hospedada la Plataforma.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <strong><span data-contrast="auto">Titulares: </span></strong
            ><span data-contrast="auto"
              >La(s) persona(s) f&iacute;sica(s) a quien(es) corresponden los
              datos personales, seg&uacute;n se definen en el art&iacute;culo 3
              fracci&oacute;n XVII de la Ley.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            >Responsable del tratamiento de los datos.
          </span></strong
        ><span data-contrast="auto"
          >En cumplimiento a lo previsto en la Ley, LFM es responsable del
          tratamiento de los datos personales proporcionados, mismos que se
          compromete a tratar en apego a los principios establecidos en el
          art&iacute;culo 6 de la Ley y de conformidad con las finalidades
          establecidas en el presente Aviso.</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            >L&iacute;mite de uso o divulgaci&oacute;n de datos.</span
          ></strong
        ><span data-contrast="auto">
          Para el ejercicio de los derechos que les concede la Ley, as&iacute;
          como, limitar el uso o divulgaci&oacute;n de sus datos personales,
          hacer preguntar o externar dudas sobre su privacidad, los Titulares
          podr&aacute;n contactar a LFM mediante correo electr&oacute;nico
          dirigido a </span
        ><span data-contrast="auto"
          ><span data-ccp-charstyle="Hyperlink"
            >hola@landfindermexico.com</span
          ></span
        ><span data-contrast="auto">
          o escrito dirigido a Manuel Encinas 1714, 1er. piso, Los Olivos,
          23040, La Paz, B.C.S.</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            ><span data-ccp-charstyle="Strong">Privacidad. </span></span
          ></strong
        ><span data-contrast="auto"
          >LFM, sus empresas afiliadas, subsidiarias o controladoras, respetan
          la privacidad de toda persona que visite el Sitio.</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Este Aviso indica la informaci&oacute;n que LFM puede recopilar y
              el uso que puede dar a esa informaci&oacute;n.&nbsp;</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Tambi&eacute;n explica las medidas de seguridad tomadas para
              proteger la informaci&oacute;n, la posibilidad de acceder a su
              informaci&oacute;n y a qui&eacute;n podr&aacute; contactar en LFM
              para que sus preguntas en relaci&oacute;n con este Aviso sean
              contestadas.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            ><span data-ccp-charstyle="Strong"
              >Recopilaci&oacute;n y Utilizaci&oacute;n de la
              Informaci&oacute;n.
            </span></span
          ></strong
        ><span data-contrast="auto"
          >Este Aviso contempla la recopilaci&oacute;n y uso de
          informaci&oacute;n personal en o a trav&eacute;s del Sitio.</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >LFM no recopilar&aacute; a trav&eacute;s del Sitio ninguna
              Informaci&oacute;n Personal a menos que el Usuario brinde en forma
              voluntaria.&nbsp;</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Si usted no desea que recopilemos su Informaci&oacute;n Personal,
              por favor abst&eacute;ngase de proporcionarla. Sin embargo,
              deber&aacute; tener en cuenta que, en caso de no proporcionarnos
              su Informaci&oacute;n Personal, no podr&aacute; completar el
              Registro y por ende acceder a la totalidad de los Servicios e
              informaci&oacute;n disponibles a trav&eacute;s del Sitio.</span
            >
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Los datos personales, incluyendo Informaci&oacute;n Personal, que
              sean recabados mediante el Sitio podr&aacute;n ser utilizados para
              las siguientes finalidades:</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
            <ol>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Proporcionar informaci&oacute;n v&iacute;a telef&oacute;nica,
                  ya sea por Whatsapp o mensaje sms, o electr&oacute;nica,
                  respecto a los Servicios.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Elaborar convenios de confidencialidad y/o contratos de
                  prestaci&oacute;n de servicios, ya sean
                  comercializaci&oacute;n en aportaci&oacute;n, comisi&oacute;n
                  mercantil, intermediaci&oacute;n, promesa de contratar, etc.,
                  a fin de prestar los Servicios.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Elaborar an&aacute;lisis de datos, t&eacute;cnicos y legal de
                  los inmuebles a publicarse en Plataforma.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Verificar la identidad del Usuario que recibir&aacute; los
                  an&aacute;lisis de datos, t&eacute;cnicos y legales de los
                  inmuebles a publicarse en Plataforma.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Llevar a cabo la transferencia de datos personales recabados
                  por LFM en favor de cualquier tercero, qui&eacute;n
                  tendr&aacute; la calidad de tercero obligado seg&uacute;n lo
                  previsto por la Ley de Protecci&oacute;n de Datos
                  Personales.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Gestionar el env&iacute;o de comunicados con avisos,
                  mensajes, novedades, invitaci&oacute;n a eventos y reuniones,
                  sea a trav&eacute;s de medios impresos, electr&oacute;nicos,
                  telef&oacute;nicos y/o presenciales con fines publicitarios,
                  promocionales, de prospecci&oacute;n comercial o de
                  mercadotecnia, a menos que se manifieste expresamente la
                  voluntad de no recibirlas.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Efectuar estudios de mercado y de consumo a efecto de ofrecer
                  productos y servicios personalizados, as&iacute; como
                  publicidad y contenidos m&aacute;s adecuados seg&uacute;n las
                  necesidades.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Proteger, reconocer o defender los derechos y propiedad de
                  LFM, as&iacute; como su informaci&oacute;n confidencial.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Mantener la seguridad de nuestro Sitio y Usuarios.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Fines mercadot&eacute;cnicos, publicitarios o de
                  prospecci&oacute;n comercial, ya sea directamente por parte de
                  LFM o cualquier tercero aliado o colaborador comercial,
                  qui&eacute;n tendr&aacute; la calidad de tercero obligado
                  seg&uacute;n lo previsto por la Ley de Protecci&oacute;n de
                  Datos Personales.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >En caso de que usted nos brinde Informaci&oacute;n Personal,
                  le comunicamos que esa Informaci&oacute;n Personal ser&aacute;
                  objeto de tratamiento automatizado e incorporada a la base de
                  datos de LFM.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >LFM en ning&uacute;n momento recopilar&aacute;
                  informaci&oacute;n sensible sobre usted.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            ><span data-ccp-charstyle="Strong"
              >Informaci&oacute;n Personal.</span
            ></span
          ></strong
        >
        <span data-contrast="auto"
          >LFM recopila Informaci&oacute;n Personal en l&iacute;nea cuando,
          entre otros casos:</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Usted realiza el Registro para hacer uso de alguno de los
              servicios disponibles del Sitio, incluyendo<span
                data-ccp-charstyle="apple-converted-space"
                > </span
              ></span
            ><em
              ><span data-contrast="auto"
                ><span data-ccp-charstyle="Emphasis">Landing</span
                ><span data-ccp-charstyle="Emphasis"> Pages,</span></span
              ></em
            ><span data-contrast="auto"
              ><span data-ccp-charstyle="apple-converted-space"> </span
              >hiperv&iacute;nculos o<span
                data-ccp-charstyle="apple-converted-space"
                > </span
              ></span
            ><em
              ><span data-contrast="auto"
                ><span data-ccp-charstyle="Emphasis">Plug-</span
                ><span data-ccp-charstyle="Emphasis">ins</span></span
              ></em
            ><span data-contrast="auto"
              ><span data-ccp-charstyle="apple-converted-space"> </span>dentro
              del propio Sitio.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto">Usted utiliza el Sitio.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Usted nos env&iacute;a preguntas, consultas o comentarios o se
              contacta con el Sitio.</span
            >
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Usted solicita informaci&oacute;n o materiales.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Usted provee de cualquier forma informaci&oacute;n al Sitio
              (chats, foros, uploads, etc.).</span
            ><span data-contrast="auto"
              >El tipo de informaci&oacute;n recopilada puede incluir nombre,
              tipo y n&uacute;mero de documento, tel&eacute;fono,
              direcci&oacute;n de correo electr&oacute;nico y/o cualquier otra
              informaci&oacute;n que permita individualizarlo.&nbsp;</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >En todos los casos que usted brinde sus datos personales,
              incluyendo Informaci&oacute;n Personal, y de acuerdo con la
              legislaci&oacute;n vigente, usted declara que sus datos
              personales, incluyendo la Informaci&oacute;n Personal brindada es
              correcta, cierta y actual.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >En los casos que nos brinde sus datos personales, incluyendo
              Informaci&oacute;n Personal, usted acepta y presta su
              consentimiento libre, expreso e informado para que dicha
              Informaci&oacute;n Personal sea utilizada con las finalidades
              mencionadas en este Aviso y autoriza a que la misma sea tratada,
              almacenada, recopilada en las bases de datos de propiedad de
              LFM.&nbsp;</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >En tal sentido, usted autoriza a que su Informaci&oacute;n
              Personal sea compartida con los clientes de LFM, as&iacute; como
              cualquier aliado o colaborador comercial. Asimismo, usted acepta y
              presta su consentimiento libre, expreso e informado con los
              t&eacute;rminos del presente Aviso.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            ><span data-ccp-charstyle="Strong"
              >Correo Electr&oacute;nico.</span
            ></span
          ></strong
        >
        <span data-contrast="auto"
          >LFM podr&aacute; enviarle correos electr&oacute;nicos en
          relaci&oacute;n con el contenido del Sitio, los servicios prestados
          por LFM o sobre su cuenta y en respuesta a sus preguntas, pedidos,
          consultas o comentarios.&nbsp;</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >LFM tambi&eacute;n le podr&aacute; enviar correos
              electr&oacute;nicos con informaci&oacute;n sobre productos y
              servicios ofrecidos por LFM y/o terceros asociados comercialmente
              que le puedan resultar de inter&eacute;s, a menos que usted
              indique expresamente que no desea recibir dichos correos
              electr&oacute;nicos a trav&eacute;s de los procesos implementados
              por LFM a tal efecto.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Usted podr&aacute; solicitar en cualquier momento el retiro o
              bloqueo de su nombre de la base de datos a la que se refiere el
              presente Aviso de siguiendo el procedimiento se&ntilde;alado en el
              mismo.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Todo correo electr&oacute;nico que reciba de LFM le
              informar&aacute; c&oacute;mo rechazar el env&iacute;o de futuros
              correos electr&oacute;nicos promocionales. Asimismo, usted
              podr&aacute; cambiar sus preferencias de recepci&oacute;n de
              correos electr&oacute;nicos publicitarios provenientes de LFM a
              trav&eacute;s de la configuraci&oacute;n de su cuenta en el propio
              Sitio en cualquier momento.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            ><span data-ccp-charstyle="Strong">Cookies.</span></span
          ></strong
        >
        <span data-contrast="auto"
          >Cuando usted ingresa en el Sitio podemos almacenar alguna
          informaci&oacute;n en su computadora bajo la forma de
          &ldquo;Cookie&rdquo; o archivo similar que puede sernos &uacute;til de
          varias formas.&nbsp;</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Con la mayor&iacute;a de los exploradores para Internet, usted
              puede borrar las Cookies del disco r&iacute;gido de su
              computadora, bloquear todas las Cookies o recibir un mensaje de
              alerta antes de que se almacene una Cookie. Por favor, consulte
              las instrucciones de su explorador para conocer m&aacute;s sobre
              estas funciones.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            ><span data-ccp-charstyle="Strong"
              >Compartiendo su Informaci&oacute;n.
            </span></span
          ></strong
        ><span data-contrast="auto"
          >La Informaci&oacute;n Personal que usted suministre ser&aacute;
          reputada y tratada como confidencial.&nbsp;</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Sin perjuicio de lo expuesto, LFM podr&aacute; compartir total o
              parcialmente la Informaci&oacute;n Personal suministrada por
              usted, incluyendo registros de comportamiento y actividad en el
              Sitio, a terceros que anuncien u ofrezcan productos o servicios en
              o a trav&eacute;s del Sitio, par&aacute;metros de inter&eacute;s
              de b&uacute;squedas as&iacute; como su historial de
              b&uacute;squeda y preferencias a empresas del grupo, as&iacute;
              como a aliados y colaboradores y anunciantes del Sitio, entidades
              financieras y compa&ntilde;&iacute;as de seguro de primera
              l&iacute;nea y reconocida trayectoria con la finalidad de
              proveerle ofertas, anuncios e informaci&oacute;n que se ajusten en
              la mayor medida posible a sus expectativas y preferencias.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >LFM tambi&eacute;n podr&aacute; transferir sus datos personales
              en los casos espec&iacute;ficamente previstos en la Ley.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            ><span data-ccp-charstyle="Strong"
              >Protegiendo su Informaci&oacute;n Personal.
            </span></span
          ></strong
        ><span data-contrast="auto"
          >Para prevenir el acceso no autorizado, mantener la precisi&oacute;n
          de los datos y asegurar el uso correcto de su Informaci&oacute;n
          Personal, LFM ha puesto en uso ciertos medios f&iacute;sicos,
          electr&oacute;nicos, administrativos y procedimientos de seguridad
          para resguardar y asegurar la Informaci&oacute;n Personal que
          recopilamos en l&iacute;nea.&nbsp;</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Nosotros resguardamos la Informaci&oacute;n Personal de acuerdo
              con est&aacute;ndares y procedimientos de seguridad establecidos y
              continuamente evaluamos nueva tecnolog&iacute;a para proteger esa
              informaci&oacute;n.&nbsp;</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >LFM garantiza que los procesos internos propios de las bases de
              datos cumplen con las obligaciones legales de seguridad y
              confidencialidad impuestas por las leyes de cada pa&iacute;s en
              materia de privacidad y protecci&oacute;n de datos
              personales.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >A pesar de lo anterior, usted reconoce que los medios
              t&eacute;cnicos existentes que brindan seguridad no son
              inexpugnables y que aun cuando se adopten todos los recaudos
              razonables de seguridad es posible sufrir manipulaciones,
              destrucci&oacute;n y/o p&eacute;rdida de informaci&oacute;n. De
              presentarse estos casos, LFM proceder&aacute; conforme las leyes
              de cada pa&iacute;s en materia de privacidad y protecci&oacute;n
              de datos lo requieran.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Los empleados de LFM son entrenados para comprender y cumplir con
              estos principios en materia de protecci&oacute;n de datos
              personales y seguridad de la informaci&oacute;n. Asimismo, los
              empleados de LFM asumen estrictos compromisos de confidencialidad
              en cuanto a la Informaci&oacute;n Personal que procesan en el
              ejercicio de sus funciones.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Usted consiente, reconoce y acepta que su informaci&oacute;n
              personal sea almacenada en la jurisdicci&oacute;n de LFM o que la
              misma pueda ser transferida, almacenada y tratada fuera de su
              pa&iacute;s de residencia.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            ><span data-ccp-charstyle="Strong">Menores de Edad.</span></span
          ></strong
        >
        <span data-contrast="auto"
          >LFM no tiene intenciones de recopilar Informaci&oacute;n Personal de
          menores de edad. Cuando corresponda, LFM le indicar&aacute;
          espec&iacute;ficamente a los menores que no brinden esa
          informaci&oacute;n Personal en nuestro Sitio y/o tomar&aacute; medidas
          razonables para obtener el consentimiento de los padres, tutor o
          representante legal para la entrega de esa Informaci&oacute;n
          Personal.</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Le informamos que en su condici&oacute;n de padre, tutor legal o
              representante ser&aacute; el responsable de que sus hijos menores
              o bajo su tutela accedan al Sitio, por lo que recomendamos
              enf&aacute;ticamente tomar las precauciones oportunas durante la
              navegaci&oacute;n en el Sitio. A este fin, le informamos que
              algunos navegadores permiten configurarse para que los
              ni&ntilde;os no puedan acceder a p&aacute;ginas
              determinadas.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            ><span data-ccp-charstyle="Strong">Links Externos.</span></span
          ></strong
        >
        <span data-contrast="auto"
          >El Sitio puede contener links hacia y provenientes de otros sitios de
          Internet.&nbsp;</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >LFM no es responsable por las pr&aacute;cticas de privacidad ni
              el tratamiento de los datos personales de esos sitios.&nbsp;</span
            >
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >LFM recomienda que consulten las pr&aacute;cticas de privacidad
              de dichos sitios de Internet antes de su utilizaci&oacute;n.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong
          ><span data-contrast="auto"
            ><span data-ccp-charstyle="Strong"
              >Derechos del Usuario.</span
            ></span
          ></strong
        >
        <span data-contrast="auto"
          >Si usted ha proporcionado Informaci&oacute;n Personal a trav&eacute;s
          de los Servicios y funciones del Sitio, usted podr&aacute; acceder a
          la misma, revisar, modificar, eliminar y actualizar su
          Informaci&oacute;n Personal en el momento que lo desee.</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Si usted desea acceder a su Informaci&oacute;n Personal o si tal
              Informaci&oacute;n Personal es incorrecta, desactualizada y/o
              suprimida, por favor tenga a bien enviar su solicitud por correo
              electr&oacute;nico de acuerdo con las normas implementadas en su
              pa&iacute;s con el asunto &ldquo;Informar&rdquo;,
              &ldquo;Rectificar&rdquo;, &ldquo;Suprimir&rdquo; y/o
              &ldquo;Actualizar&rdquo; y/o cualquier otro seg&uacute;n
              corresponda, conjuntamente con el objeto de su
              requerimiento.&nbsp;</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Dicha solicitud deber&aacute; contener como m&iacute;nimo la
              siguiente informaci&oacute;n:</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
            <ol>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Nombre y domicilio, a efecto de dar respuesta a su solicitud
                  en los plazos establecidos en la ley aplicable;</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Copia de su documento de identidad, pasaporte o cualquier
                  otro documento que acredite su identidad o acredite la
                  personalidad de su representante legal, y en caso que la
                  solicitud se realice a trav&eacute;s del representante legal,
                  acompa&ntilde;ar el documento que acredita sus facultades como
                  representante;</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto"
                  >Descripci&oacute;n clara y precisa de los datos personales
                  sobre los que se busca ejercer alguno de los derechos antes
                  mencionados de acceso, rectificaci&oacute;n, oposici&oacute;n,
                  cancelaci&oacute;n o revocaci&oacute;n, as&iacute; como la
                  descripci&oacute;n clara y precisa de su solicitud;</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
              <li
                data-leveltext="%1."
                data-font="Helvetica Neue"
                data-listid="6"
                data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto">Fecha y firma del solicitante y</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;c</span
                ><span data-contrast="auto"
                  >ualquier otro elemento o documento que facilite la
                  localizaci&oacute;n de dichos datos personales.</span
                ><span data-ccp-props='{"335551550":6,"335551620":6}'
                  >&nbsp;</span
                >
              </li>
            </ol>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Asimismo, si usted se suscribe a alguno de los servicios o
              comunicaciones que ofrece LFM podr&aacute; dar de baja su
              suscripci&oacute;n en cualquier momento siguiendo las
              instrucciones incluidas en cada comunicaci&oacute;n.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >LFM cooperar&aacute; con las autoridades de cada pa&iacute;s
              cuando &eacute;stas requieran formalmente cualquier
              informaci&oacute;n relacionada con nuestras bases de datos.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong><span data-contrast="auto">Derechos ARCO.</span></strong
        ><span data-contrast="auto">
          El presente Aviso de Privacidad se rige por las leyes de los Estados
          Unidos Mexicanos.</span
        ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Su Informaci&oacute;n Personal, ser&aacute; utilizada para: (i)
              mantener la relaci&oacute;n jur&iacute;dica entre LFM y el titular
              de dicha Informaci&oacute;n Personal, (ii) contar con datos de
              contacto para requerimientos propios del trabajo de LFM o para
              situaciones de emergencia, y (iii) brindar acceso,
              informaci&oacute;n y servicios a trav&eacute;s del Sitio.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >La Informaci&oacute;n Personal recabada a trav&eacute;s del Sitio
              puede ser transferida para los fines anteriormente descritos a
              nuestras filiales, entidades asociadas, colaboradores, aliados y
              proveedores de servicios, ya sea en territorio nacional u otros
              pa&iacute;ses. Dichos pa&iacute;ses pueden no tener la misma
              normativa de protecci&oacute;n de datos que la que existe en
              M&eacute;xico. Cuando transfiramos su Informaci&oacute;n Personal
              a otros pa&iacute;ses, la protegeremos en la forma descrita en
              este documento.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Usted tiene derecho de acceder a la Informaci&oacute;n Personal
              que poseemos y a los detalles de su tratamiento, as&iacute; como a
              rectificarlos en caso de ser inexactos o incompletos, cancelarlos
              u oponerse o limitar su tratamiento para fines
              espec&iacute;ficos.&nbsp;</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Para ejercer los derechos de acceso, rectificaci&oacute;n,
              cancelaci&oacute;n u oposici&oacute;n, deber&aacute; cumplir con
              los requerimientos del art&iacute;culo 29 de la Ley de
              Protecci&oacute;n de Datos Personales, para lo cual deber&aacute;
              presentar una solicitud por escrito debidamente firmada a LFM, y
              deber&aacute; identificarse a satisfacci&oacute;n de LFM, mediante
              documento oficial.&nbsp;</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >LFM dar&aacute; respuesta a cada solicitud en un plazo
              m&aacute;ximo de 20 (veinte) d&iacute;as h&aacute;biles (o el
              plazo que corresponda conforme a la legislaci&oacute;n aplicable
              vigente a la fecha de la solicitud), a partir de que se reciba la
              solicitud que cumpla con los requerimientos del art&iacute;culo 29
              de la Ley de Protecci&oacute;n de Datos Personales y que se
              presente la mencionada solicitud e identificaci&oacute;n en los
              t&eacute;rminos indicados.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >El contacto en M&eacute;xico para ejercer los derechos de acceso,
              rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n de la
              Informaci&oacute;n Personal es:<span
                data-ccp-charstyle="apple-converted-space"
                > </span
              ></span
            ><span data-contrast="none"
              ><span data-ccp-charstyle="Hyperlink"
                >hola@landfindermexico.com</span
              ></span
            ><span data-contrast="auto">.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
      <li
        data-leveltext="%1."
        data-font="Helvetica Neue"
        data-listid="6"
        data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
        data-aria-posinset="1"
        data-aria-level="1"
      >
        <strong><span data-contrast="auto">Vigencia. </span></strong
        ><span data-contrast="auto"
          >Este documento constituye la versi&oacute;n m&aacute;s actualizada de
          este Aviso (V1.22.11.09), el cual es obligatorio para todos los
          Usuarios que visiten el Sitio y/o usen los Servicios.</span
        >
        <ol>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >El Usuario, de forma expresa, declara que ha le&iacute;do,
              entiende y acepta la totalidad del Aviso del Sitio.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >LFM se reserva el derecho a modificar este Aviso de Privacidad
              peri&oacute;dicamente, en cuyo caso el Aviso actualizado se
              publicar&aacute; en este mismo Sitio, siendo obligaci&oacute;n de
              los usuarios revisar regularmente esta secci&oacute;n a fin de
              informarse de cualquier cambio que se pueda haber
              producido.&nbsp;</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >LFM publicar&aacute; en el Sitio, cuando fuere posible, un aviso
              de las modificaciones del Aviso.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
          <li
            data-leveltext="%1."
            data-font="Helvetica Neue"
            data-listid="6"
            data-list-defn-props='{"335552541":0,"335559684":-1,"335559685":360,"335559991":360,"469769242":[65533,0],"469777803":"left","469777804":"%1.","469777815":"multilevel","469778510":"default"}'
            data-aria-posinset="1"
            data-aria-level="1"
          >
            <span data-contrast="auto"
              >Los cambios a los T&eacute;rminos y Condiciones no se
              aplicar&aacute;n de manera retroactiva y entrar&aacute;n en vigor
              una vez publicados.</span
            ><span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  metaInfo: {
    title: "Land Finder México",
    titleTemplate: "%s | Aviso de Privacidad",
  },
};
</script>

<style lang="scss" scoped>
ol {
  margin-bottom: 2rem;
}
</style>
