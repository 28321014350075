import { render, staticRenderFns } from "./UserDataFormComp.vue?vue&type=template&id=4833623a&scoped=true&"
import script from "./UserDataFormComp.vue?vue&type=script&lang=js&"
export * from "./UserDataFormComp.vue?vue&type=script&lang=js&"
import style0 from "./UserDataFormComp.vue?vue&type=style&index=0&id=4833623a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4833623a",
  null
  
)

export default component.exports