import { render, staticRenderFns } from "./PrivacyView.vue?vue&type=template&id=7fbb43dd&scoped=true&"
import script from "./PrivacyView.vue?vue&type=script&lang=js&"
export * from "./PrivacyView.vue?vue&type=script&lang=js&"
import style0 from "./PrivacyView.vue?vue&type=style&index=0&id=7fbb43dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fbb43dd",
  null
  
)

export default component.exports