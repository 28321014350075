<template>
  <div class="row text-center support-view align-content-center">
    <div class="col cols-12">
      <h5 class="mt-5">Para dudas o aclaraciones contactanos al</h5>
      <h4 class="mt-5"><i class="ti ti-phone"/> +52 612 120 2215</h4>
      <h5  class="mt-5">o</h5>
      <h4  class="mt-5"><i class="ti ti-mail"/> hola@landfindermexico.com</h4>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.support-view{
  height: 100%;
}
</style>